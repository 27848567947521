import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./servicesStyles";

const Services = () => {
	const classes = useStyles();

	return (
		<Grid container className={classes.servicesGridContainer}>
			<Grid
				container
				justifyContent="center"
				className={classes.servicesHeader}
			>
				<Grid container justifyContent="center">
					<Typography className={classes.servicesText}>Services</Typography>
				</Grid>
				<Grid className={classes.gridGrid}>
					<Divider className={classes.gridBorder} />
				</Grid>
			</Grid>

			<Grid container className={classes.servicesContainer}>
				<Grid item sm={6} xs={12} className={classes.servicesImage1}></Grid>

				<Grid
					item
					sm={6}
					xs={12}
					container
					alignItems="center"
					className={classes.servicesGroup}
				>
					<Grid container justifyContent="flex-start">
						<Grid container justifyContent="flex-start">
							<Typography className={classes.professionalText}>
								Professional Services
							</Typography>
						</Grid>
						<Grid className={classes.gridBorderProGrid}>
							<Divider className={classes.gridBorderPro} />
						</Grid>
					</Grid>
					<Typography className={classes.professionalMetaText}>
						Our team of SMEs and technologists will collaborate with and guide
						you throughout the workflow configuration and SCM and ERP
						integration processes.
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Services;
