import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
  },
  headerItem: {
    fontSize: "1.25rem",
    fontFamily: "OutfitRegular",
    color: "rgba(255, 255, 255, 0.7)",
    "@media (max-width: 769px)": {
      color: "#555555",
      marginBottom: "0.5rem !important",
      textAlign: "left !important",
    },
  },
  toolbar: {
    "@media (max-width: 769px)": {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
  },
  logo: {
    "@media (max-width: 769px)": {
      width: "100%",
      height: "100%",
      minWidth: "7rem",
    },
  },
  headerWrapper: {
    "@media (max-width: 769px)": {
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  hamburgerIcon: {
    cursor: "pointer",
  },
  drawerRoot: {
    "& .MuiPaper-root": {
      padding: "2rem",

      "@media (max-width: 769px)": {
        width: "12rem",
      },
      "@media (max-width: 475px)": {
        width: "100%",
        left: 0,
      },
    },
  },
  headerItemWrapper: {
    marginTop: "2rem",
  },
  closeIconWrapper: {
    position: "absolute",
    top: "1rem",
    right: "1.5rem",
    "@media (max-width: 475px)": {
      position: "fixed",
    },
    "& svg": {
      width: "auto",
      height: "2rem",
      cursor: "pointer",
      color: "#555555",
    },
  },
}));
