import { makeStyles } from "@mui/styles";
import { Employees } from "../../images"; // Import using relative path

export const useStyles = makeStyles((theme) => ({
	companyGridContainer: {
		paddingTop: "7.5rem",
		paddingBottom: "7.5rem",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "0",
		},
	},
	companyText: {
		fontFamily: "OutfitRegular",
		fontWeight: 700,
		fontSize: "3rem !important",
		lineHeight: "3.75rem !important",
		color: "#222222",
		[theme.breakpoints.down("sm")]: {
			fontSize: "2.5rem !important",
			fontWeight: 700,
			lineHeight: "3rem !important",
		},
	},
	companyMetaText: {
		fontFamily: "OutfitRegular",
		fontWeight: 400,
		fontSize: "1.5rem !important",
		lineHeight: "2.5rem !important",
		color: "#555555",
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.2rem !important",
			fontWeight: 400,
			lineHeight: "2rem !important",
		},
	},
	employeesGrid: {
		backgroundImage: `url(${Employees})`,
		//backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		objectFit: "fill",
		backgroundSize: "contain",
		backgroundPosition: "center",
		paddingTop: "10rem",
		paddingBottom: "10rem",
	},
	listHeaderText: {
		fontFamily: "OutfitRegular",
		fontWeight: 400,
		fontSize: "1.5rem !important",
		lineHeight: "2.5rem !important",
		color: "#555555",
		[theme.breakpoints.down("sm")]: {
			lineHeight: "2.2rem !important",
		},
	},
	companyGrid: {
		marginTop: "0",
		[theme.breakpoints.down("sm")]: {
			marginTop: "2rem",
		},
	},
	companyTextContainer: {
		marginTop: "0",
		[theme.breakpoints.down("sm")]: {
			marginTop: "1rem",
		},
	},
}));
