import { makeStyles } from "@mui/styles";
import { Technology } from "../../images"; // Import using relative path

export const useStyles = makeStyles((theme) => ({
	technologyGridContainer: {
		paddingTop: "11.188rem",
		paddingBottom: "11.188rem",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "0",
			paddingTop: "0",
		},
	},
	technologyGrid: {
		padding: "7rem 0",
		[theme.breakpoints.down("sm")]: {
			padding: "3rem 0",
		},
	},
	technologyText: {
		fontSize: "3rem !important",
		fontWeight: 700,
		lineHeight: "3.75rem !important",
		fontFamily: "OutfitRegular",
		color: "#222222",
		[theme.breakpoints.down("sm")]: {
			fontSize: "2.5rem !important",
			fontWeight: 700,
			lineHeight: "3rem !important",
		},
	},
	technologyMetaText: {
		fontSize: "1.5rem !important",
		fontWeight: 400,
		lineHeight: "2.5rem !important",
		fontFamily: "OutfitRegular",
		color: "#555555",
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.2rem !important",
			fontWeight: 400,
			lineHeight: "2rem !important",
		},
	},
	gridGrid: {
		background: "#00CC8F",
		width: "13.549rem",
		[theme.breakpoints.down("md")]: {
			marginTop: "1rem",
			marginBottom: "1rem",
		},
	},
	gridBorder: {
		background: "#00CC8F",
		width: "13.549rem",
		height: "2.5px",
	},
	technologyImage: {
		backgroundImage: `url(${Technology})`,
		backgroundRepeat: "no-repeat",
		objectFit: "fill",
		backgroundSize: "contain",
		backgroundPosition: "center",
		paddingTop: "18rem",
		paddingBottom: "18rem",
		[theme.breakpoints.down("sm")]: {
			padding: "10rem 0",
		},
	},
}));
