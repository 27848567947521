import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./technologyStyles";

const Technology = () => {
	const classes = useStyles();

	return (
		<Grid container className={classes.technologyGridContainer}>
			<Grid
				item
				sm={7}
				xs={12}
				container
				alignItems="center"
				className={classes.technologyGrid}
			>
				<Grid container>
					<Typography className={classes.technologyText}>Technology</Typography>
				</Grid>

				<Grid className={classes.gridGrid}>
					<Divider className={classes.gridBorder} />
				</Grid>
				<Typography className={classes.technologyMetaText}>
					Our innovation in financial process solutions is not limited to
					automation and rich functionality – it applies to our technology as
					well. From Python to HTML5 to REST API, we keep our solution current
					with the latest and best technology. We strive our best and beyond to
					maintain and constantly enhance our technology stack to leverage
					innovative technologies and to provide our customers the best user
					experience.
				</Typography>
			</Grid>
			<Grid item xs={12} sm={5} className={classes.technologyImage}></Grid>
		</Grid>
	);
};

export default Technology;
