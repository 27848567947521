import { makeStyles } from "@mui/styles";
import { Services1, SupportImage, TrainingImage } from "../../images";

export const useStyles = makeStyles((theme) => ({
	servicesGridContainer: {
		paddingTop: "6.25rem",
		paddingBottom: "4rem",
	},
	gridGrid: {
		width: "11.813rem",
		height: "2.5px",
		marginTop: "1rem",
		marginBottom: "1rem",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	gridBorder: {
		background: "#00CC8F",
		width: "11.813rem",
		height: "2.5px",
		[theme.breakpoints.down("sm")]: {
			width: "35%",
		},
	},
	servicesText: {
		fontSize: "3rem !important",
		fontWeight: 700,
		lineHeight: "3.75rem !important",
		fontFamily: "OutfitRegular",
		color: "#222222",
	},
	servicesImage1: {
		backgroundImage: `url(${Services1})`,
		//backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		objectFit: "fill",
		backgroundSize: "contain",
		backgroundPosition: "center",
		//paddingTop: "10rem",
		//paddingBottom: "10rem",
	},
	professionalText: {
		fontSize: "2.125rem !important",
		fontWeight: 600,
		lineHeight: "2.688rem !important",
		fontFamily: "OutfitRegular",
		color: "#0B3192",
	},
	professionalMetaText: {
		fontSize: "1.5rem !important",
		fontWeight: 400,
		lineHeight: "2.5rem !important",
		fontFamily: "OutfitRegular",
		color: "#555555",
	},
	gridBorderPro: {
		border: "2px solid #00CC8F",
		width: "6.25rem",
		height: "2px",
		marginTop: "0.875rem",
		marginBottom: "0.875rem",
	},
	supportText: {
		fontSize: "2.125rem !important",
		fontWeight: 600,
		lineHeight: "2.688rem !important",
		fontFamily: "OutfitRegular",
		color: "#0B3192",
		[theme.breakpoints.down("sm")]: {
			fontSize: "2rem !important",
			lineHeight: "2rem !important",
		},
	},
	supportMetaText: {
		fontSize: "1.5rem !important",
		fontWeight: 400,
		lineHeight: "2.5rem !important",
		fontFamily: "OutfitRegular",
		color: "#555555",
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.2rem !important",
			lineHeight: "2rem !important",
		},
	},
	supportImage: {
		backgroundImage: `url(${SupportImage})`,
		backgroundRepeat: "no-repeat",
		objectFit: "fill",
		backgroundSize: "contain",
		backgroundPosition: "center",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "10rem",
			paddingBottom: "10rem",
		},
	},
	trainingImage: {
		backgroundImage: `url(${TrainingImage})`,
		//backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		objectFit: "fill",
		backgroundSize: "contain",
		backgroundPosition: "center",
		//paddingTop: "10rem",
		//paddingBottom: "10rem",
	},
	supportContainer: {
		paddingBottom: "6.25rem",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "0",
		},
	},
	supportTextContainer: {
		padding: "6rem 0",
		[theme.breakpoints.down("sm")]: {
			padding: "2rem 0",
		},
	},
}));
