import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./supportStyles";

const Support = () => {
	const classes = useStyles();

	return (
		<Grid container className={classes.supportContainer}>
			<Grid
				item
				sm={6}
				xs={12}
				container
				alignItems="center"
				className={classes.supportTextContainer}
			>
				<Grid container justifyContent="flex-start">
					<Grid container>
						<Typography className={classes.supportText}>Support</Typography>
					</Grid>
					<Grid className={classes.gridGrid}>
						<Divider className={classes.gridBorder} />
					</Grid>
				</Grid>
				<Typography className={classes.supportMetaText}>
					InfoMoksha prides itself in offering the best service with as little
					as 2 hour or less turnaround. Application pricing is based on 100%
					SaaS model, which means subscription price includes ongoing support.
				</Typography>
			</Grid>
			<Grid item sm={6} xs={12} className={classes.supportImage}></Grid>
		</Grid>
	);
};

export default Support;
