import { Grid, Typography } from "@mui/material";
import React from "react";
import { OraclePartner } from "../../images";
import { useStyles } from "./bottomStyles";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

const Bottom = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(769));

  return (
    <Grid container justifyContent="space-between" className={classes.footerWrapper} >
      <Grid
        item
        md={6}
        xs={12}
        container
        className={classes.reservedText}
        alignItems="center"
      >
        <Typography className={classes.rightText}>
          © infomoksha {currentYear}. All Rights Reserved.
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        gap={isMobileView ? 0 : "2.5rem"}
        container
        alignItems="center"
        justifyContent="center"
        flexWrap={"nowrap"}
        className={classes.privacyWrapper}
      >
        <Grid item xs={12}>
          <a
            href="http://www.infomoksha.com/privacy_policy.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Typography className={classes.rightText}>
              {" "}
              Privacy policy
            </Typography>
          </a>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.rightText} align="flex-start">
            <img src={OraclePartner} alt="oracle" width="200px" />{" "}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Bottom;
