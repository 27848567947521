import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./trainingsStyles";

const Trainings = () => {
	const classes = useStyles();

	return (
		<Grid container className={classes.trainingsContainer}>
			<Grid item sm={6} xs={12} className={classes.trainingImage}></Grid>
			<Grid
				item
				sm={6}
				xs={12}
				container
				alignItems="center"
				sx={{ padding: "6rem 0" }}
			>
				<Grid container justifyContent="flex-start">
					<Grid container>
						<Typography className={classes.supportText}>Training</Typography>
					</Grid>
					<Grid className={classes.gridGrid}>
						<Divider className={classes.gridBorderPro} />
					</Grid>
				</Grid>
				<Typography className={classes.supportMetaText}>
					We offer super user training during initial implementation phase per
					train-the-trainer model. In addition, we also offer on demand super
					user or end user training.
				</Typography>
			</Grid>
		</Grid>
	);
};

export default Trainings;
