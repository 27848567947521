import { Container } from "@mui/material";
import React, {useState} from "react";
import Bottom from "../bottom";
import Company from "../company";
import Header from "../header/Header";
import Hero from "../hero";
import Query from "../query";
import ServiceData from "../servicedata";
import Services from "../services";
import Solution from "../solution";
import Support from "../support";
import Technology from "../technology";
import Trainings from "../trainings";

import { useStyles } from "./homeStyles";

const Home = () => {
	const [confirmConsent, setConfirmConsent] = useState(localStorage.getItem('confirmConsent'))
	const classes = useStyles();

	const saveConsent = () => {
		localStorage.setItem('confirmConsent', "yes")
		setConfirmConsent("yes")
	}

	return (
		<>
			<Header />
			<section id="1" className={classes.heroSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Hero />
				</Container>
			</section>
			<section id="company" className={classes.companySection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Company />
				</Container>
			</section>
			<section id="3" className={classes.serviceSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<ServiceData />
				</Container>
			</section>
			<section id="technology" className={classes.technologySection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Technology />
				</Container>
			</section>
			<section id="solutions" className={classes.solutionSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Solution />
				</Container>
			</section>
			<section id="services" className={classes.servicesSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Services />
				</Container>
			</section>
			<section id="7" className={classes.supportSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Support />
				</Container>
			</section>
			<section id="8" className={classes.trainingSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Trainings />
				</Container>
			</section>

			<section id="10" className={classes.querySection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Query />
				</Container>
			</section>
			<section id="partners" className={classes.bottomSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Bottom />
				</Container>
			</section>
			{confirmConsent !== "yes" && <div className={classes.cookieConsentBanner} id="consentBanner">
				<div className={classes.cookieConsentBannerInner}>
					<div className={classes.cookieConsentBannerCopy}>
						<div className={classes.cookieConsentBannerHeader}>THIS WEBSITE USES COOKIES</div>
						<div className={classes.cookieConsentBannerDescription}>This website uses cookies to ensure you get the best
							experience on our website.
							<a aria-label="learn more about cookies" role="button" tabIndex="0" className="cc-link"
								 href="http://www.infomoksha.com/privacy_policy.pdf" rel="noopener noreferrer nofollow" target="_blank">Learn
								more</a>
						</div>

					</div>

					<div className={classes.cookieConsentBannerActions}>
						<a href="#" onClick={saveConsent} className={classes.cookieConsentBannerCta}>
							Got it!
						</a>


					</div>
				</div>
			</div>}
		</>
	);
};

export default Home;
