import { Grid } from "@mui/material";
import React from "react";
import { useStyles } from "./serviceDataStyles";

const ServiceData = () => {
	const classes = useStyles();

	return (
		<Grid container className={classes.serviceGridContainer}>
			<Grid container className={classes.serviceImage1}></Grid>
			<Grid container className={classes.serviceImage2}></Grid>
		</Grid>
	);
};

export default ServiceData;
