import { makeStyles } from "@mui/styles";
import {
	CompanyLayer,
	HeroLayer,
	QueryLeftLayer,
	QueryRightLayer,
	ServiceDataBottomLeftLayer,
	ServiceDataTopRightLayer,
	ServicesTopRightLayer,
	SupportBottomLeftLayer,
	TechnologyLayer,
	TrainingBottomRightLayer,
} from "../../images";

export const useStyles = makeStyles((theme) => ({
	heroSection: {
		//height: "calc(100vh - 4rem)",
		backgroundColor: theme.palette.primary.main,
		backgroundImage: `url(${HeroLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom left -50px",
	},
	companySection: {
		backgroundColor: "#ffffff",
		backgroundImage: `url(${CompanyLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom left -50px",
	},
	serviceSection: {
		backgroundColor: theme.palette.primary.main,
		backgroundImage: `url(${ServiceDataBottomLeftLayer}), url(${ServiceDataTopRightLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom -340px left -50px, top -300px right -50px",
	},
	technologySection: {
		backgroundColor: "#F7F9FE",
		backgroundImage: `url(${TechnologyLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom -80px right -80px",
	},
	solutionSection: {
		backgroundColor: "#FFFFFF",
	},
	servicesSection: {
		backgroundColor: "#F7F9FE",
		backgroundImage: `url(${ServicesTopRightLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "top 320px right -80px",
	},
	supportSection: {
		backgroundColor: "#F7F9FE",
		backgroundImage: `url(${SupportBottomLeftLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom -110px left -70px",
	},
	trainingSection: {
		backgroundColor: "#F7F9FE",
		backgroundImage: `url(${TrainingBottomRightLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom -100px right -70px",
	},
	partnersSection: {
		backgroundColor: "#FFFFFF",
		backgroundImage: `url(${SupportBottomLeftLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "top 80px left -70px",
	},
	querySection: {
		backgroundColor: "#0B3192",
		backgroundImage: `url(${QueryLeftLayer}), url(${QueryRightLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "top 0px left -70px, top 350px right -30px",
	},
	bottomSection: {
		backgroundColor: "#FFFFFF",
	},
	cookieConsentBanner: {
	position: "fixed",
	bottom: 0,
	left: 0,
	zIndex: 2147483645,
	boxSizing: "border-box",
	width: "100%",
	backgroundColor: "#F1F6F4"
},
 	cookieConsentBannerInner: {
	maxWidth: "960px",
	margin: "0 auto",
	padding: "32px 0"
},
 	cookieConsentBannerCopy: {
	marginBottom: "16px",
},
	cookieConsentBannerHeader: {
		marginBottom: "8px",
		fontFamily: "'CeraPRO-Bold', sans-serif, arial",
		fontWeight: "normal",
		fontSize: "16px",
		lineHeight: "24px"
},
 	cookieConsentBannerDescription: {
		fontFamily: "'CeraPRO-Regular', sans-serif, arial",
		fontWeight: "normal",
		color: "#838F93",
		fontSize: "16px",
		lineHeight: "24px"
},
 cookieConsentBannerCta: {
	boxSizing: "border-box",
	display: "inline-block",
	minWidth: "164px",
	padding: "11px 13px",
	borderRadius: "2px",
	backgroundColor: "#2CE080",
	color: "#FFF",
	textDecoration: "none",
	textAlign: "center",
	fontFamily: "'CeraPRO-Regular', sans-serif, arial",
	fontWeight: "normal",
	fontSize: "16px",
	lineHeight: "20px",
  ":hover": {
		 backgroundColor: "#20BA68"
	 },
  ":last-child": {
	 marginLeft: "16px"
	}
},
 cookieConsentBannerCtaSecondary: {
	padding: "9px 13px",
	border: "2px solid #3A4649",
	backgroundColor: "transparent",
	color: "#2CE080",
 ":hover": {
	 borderColor: "#838F93",
	 backgroundColor: "transparent",
	 color: "#22C870"
 }
},

}));
