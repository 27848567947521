import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	serviceGridContainer: {
		paddingTop: "7.5rem",
		paddingBottom: "7.5rem",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "3rem !important",
			paddingBottom: "3rem !important",
		},
	},
	heroText: {
		fontSize: "4rem !important",
		fontWeight: 500,
		lineHeight: "4.412rem !important",
		fontFamily: "OutfitRegular",
		color: "#fff",
	},
	serviceImage1: {
		backgroundImage: `url(images/serviceImage1.png)`,
		backgroundRepeat: "no-repeat",
		objectFit: "fill",
		backgroundSize: "contain",
		backgroundPosition: "center",
		paddingTop: "7.5rem",
		paddingBottom: "6.5rem",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "3rem",
			paddingBottom: "3rem",
		},
	},
	serviceImage2: {
		backgroundImage: `url(images/serviceImage2.png)`,
		backgroundRepeat: "no-repeat",
		objectFit: "fill",
		backgroundSize: "contain",
		backgroundPosition: "center",
		paddingTop: "7.5rem",
		paddingBottom: "6.5rem",
		marginTop: "6.5rem",
		[theme.breakpoints.down("sm")]: {
			marginTop: "0",
			paddingTop: "3rem",
			paddingBottom: "3rem",
		},
	},
}));
