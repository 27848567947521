import { ThemeProvider } from "@mui/material";
import React from "react";
import Home from "./components/home";
import theme from "./utils/theme/index";

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Home />
		</ThemeProvider>
	);
};

export default App;
