const HEADER_ITEMS = [
	{
		id: 1,
		itemName: "Technology",
		itemLink: "#technology",
	},
	{
		id: 2,
		itemName: "Solutions",
		itemLink: "#solutions",
	},
	{
		id: 3,
		itemName: "Services",
		itemLink: "#services",
	},
	{
		id: 4,
		itemName: "Company",
		itemLink: "#company",
	},
	{
		id: 5,
		itemName: "Partners",
		itemLink: "#partners",
	},
];

export default HEADER_ITEMS;
