import {
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Typography,
} from "@mui/material";

import React from "react";
import { CeoImage, SelectedIcon } from "../../images";
import { useStyles } from "./companyStyles";

const Company = () => {
	const classes = useStyles();

	return (
		<>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				className={classes.companyGridContainer}
			>
				<Grid item xs={12} sm={12} md={4} container justifyContent="center">
					<CeoImage />
				</Grid>
				<Grid item xs={12} sm={12} md={8}>
					<Grid container className={classes.companyGrid}>
						<Typography className={classes.companyText}>Company</Typography>
					</Grid>
					<Grid container className={classes.companyTextContainer}>
						<Typography className={classes.companyMetaText}>
							InfoMoksha’s mission is to provide finance and accounting process
							solutions from an end user perspective. While our solutions are
							based on best technologies, we strongly feel that emphasis needs
							to be usability from an end user perspective. Infomoksha has
							always prioritized user experience, long before DXP became a
							buzzword.
						</Typography>
						<Typography className={classes.companyMetaText} mt={2}>
							We can have lengthy discussions on technology, but none of that
							really matters if the end-user cannot navigate through and operate
							the application intuitively. User experience matters as much as
							product features and functions. All our solution designs start
							with end user experience as the first step and then we research
							the best technology that would meet that requirement as opposed to
							starting from a technology perspective. All our applications
							provide similar user experience, whether navigating through the
							application, performing a specific task, or collaborating with a
							colleague.
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid container sx={{ margin: "2.5rem 0" }}>
				<Grid item sm={8} xs={12} container alignItems="center">
					<List
						subheader={
							<ListSubheader
								component="div"
								id="nested-list-subheader"
								className={classes.listHeaderText}
							>
								As such, from our point of view, key steps to any solution
								design should be
							</ListSubheader>
						}
					>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<SelectedIcon />
								</ListItemIcon>
								<ListItemText
									className={classes.listHeaderText}
									primary="Is there a gap and a need for such a solution."
								/>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									{" "}
									<SelectedIcon />
								</ListItemIcon>
								<ListItemText
									className={classes.listHeaderText}
									primary="How easily can the end user work with this solution."
								/>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									{" "}
									<SelectedIcon />
								</ListItemIcon>
								<ListItemText
									className={classes.listHeaderText}
									primary="What is the best technology that would meet that end."
								/>
							</ListItemButton>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} sm={4} className={classes.employeesGrid}></Grid>
			</Grid>
		</>
	);
};

export default Company;
