import { makeStyles } from "@mui/styles";
import { NextIcon, PrevIcon } from "../../images"; // Import using relative path

export const useStyles = makeStyles((theme) => ({
	solutionGridContainer: {
		paddingTop: "6.25rem",
		paddingBottom: "6.25rem",
		[theme.breakpoints.down("sm")]: {
			padding: "2rem 0",
		},
	},
	solutionText: {
		fontSize: "3rem !important",
		fontWeight: 700,
		lineHeight: "3.78rem !important",
		fontFamily: "OutfitRegular",
		color: "#222222",
		[theme.breakpoints.down("sm")]: {
			fontSize: "2.5rem !important",
			fontWeight: 700,
			lineHeight: "3rem !important",
		},
	},
	solutionMetaText: {
		fontSize: "1.5rem !important",
		fontWeight: 400,
		lineHeight: "2.5rem !important",
		fontFamily: "OutfitRegular",
		color: "#222222",
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.2rem !important",
			lineHeight: "2rem !important",
		},
	},
	powerCloseText: {
		fontWeight: 600,
		fontSize: "2.125rem !important",
		lineHeight: "2.688rem !important",
		color: "#0B3192",
		[theme.breakpoints.down("sm")]: {
			fontSize: "2rem !important",
			lineHeight: "2rem !important",
		},
	},
	hubicContainer: {
		marginTop: "6rem",
		[theme.breakpoints.down("sm")]: {
			marginTop: "2rem",
		},
	},
	gridGrid: {
		background: "#00CC8F",
		width: "11.813rem",
		marginTop: "2.125rem",
		marginBottom: "2.125rem",
	},
	gridBorder: {
		background: "#00CC8F",
		width: "11.813rem",
		height: "2.5px",
	},
	gridHubicGrid: {
		background: "#00CC8F",
		width: "6.25rem",
		marginTop: "1rem",
		marginBottom: "1rem",
	},
	gridHubicBorder: {
		background: "#00CC8F",
		width: "6.25rem",
		height: "2.5px",
	},
	powerCloseGrid: {
		width: "6.25rem",
		height: "1px",
		marginTop: "0.875rem",
		marginBottom: "0.875rem",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	powerCloseBorder: {
		background: "#00CC8F",
		width: "6.25rem",
		height: "2px",
		[theme.breakpoints.down("sm")]: {
			width: "55%",
		},
	},
	solutionCircles: {
		// backgroundImage: `url(${SolutionCircles})`,
		// backgroundRepeat: "no-repeat",
		// objectFit: "fill",
		// backgroundSize: "contain",
		// backgroundPosition: "center",
		paddingTop: "5rem",
		paddingBottom: "5rem",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "1rem",
			paddingBottom: "1rem",
		},
	},
	solutionCircles2: {
		// backgroundImage: `url(${SolutionCircles})`,
		// backgroundRepeat: "no-repeat",
		// objectFit: "fill",
		// backgroundSize: "contain",
		// backgroundPosition: "center",
		// paddingTop: "10rem",
		// paddingBottom: "10rem",
		// [theme.breakpoints.down("sm")]: {
		// 	marginTop: "3rem !important",
		// 	paddingTop: "5rem",
		// 	paddingBottom: "4rem",
		// },
		[theme.breakpoints.down("sm")]: {
			paddingTop: "2rem",
		},
	},
	powerCloseContainer: {
		marginTop: "0",
		paddingTop: "5rem",
		paddingBottom: "5rem",
		[theme.breakpoints.down("sm")]: {
			marginTop: "0rem !important",
		},
	},
	swiper: {
		"& .swiper-button-next": {
			backgroundImage: `url(${NextIcon}) !important`,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundSize: "100% auto",
			padding: "1.5%",
			marginRight: "1%",
			[theme.breakpoints.down("sm")]: {
				marginRight: "-2%",
			},
		},
		"& .swiper-button-prev": {
			backgroundImage: `url(${PrevIcon}) !important`,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundSize: "100% auto",
			padding: "1.5%",
			marginLeft: "1%",
			[theme.breakpoints.down("sm")]: {
				marginLeft: "-2%",
			},
		},
		"& .swiper-button-next::after": {
			display: "none",
		},
		"&  .swiper-button-prev::after": {
			display: "none",
		},
	},
	hubicText: {
		fontWeight: 600,
		fontSize: "2.125rem !important",
		lineHeight: "2.688rem !important",
		color: "#0B3192",
		fontFamily: "OutfitRegular",
		[theme.breakpoints.down("sm")]: {
			fontSize: "2rem !important",
			lineHeight: "2rem !important",
		},
	},
	hubicMetaText: {
		fontWeight: 400,
		fontSize: "1.5rem !important",
		lineHeight: "2.5rem !important",
		color: "#222222",
		fontFamily: "OutfitRegular",
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.2rem !important",
			lineHeight: "2rem !important",
		},
	},
	swiperImage1: {
		// backgroundImage: `url(images/1.png)`,
		// backgroundRepeat: "no-repeat",
		// objectFit: "fill",
		// backgroundSize: "contain",
		// backgroundPosition: "center",
	},
	swiperImage2: {
		// backgroundImage: `url(images/2.png)`,
		// backgroundRepeat: "no-repeat",
		// objectFit: "fill",
		// backgroundSize: "contain",
		// backgroundPosition: "center",
	},
}));
