import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Solution1, Solution3, Solution4 } from "../../images";
import { useStyles } from "./solutionStyles";

const Solution = () => {
	const classes = useStyles();

	return (
		<Grid container className={classes.solutionGridContainer}>
			<Grid container justifyContent="center">
				<Grid container justifyContent="center">
					<Typography className={classes.solutionText}>Solution</Typography>
				</Grid>
				<Grid className={classes.gridGrid}>
					<Divider className={classes.gridBorder} />
				</Grid>
			</Grid>

			<Grid item md={8} sm={12} xs={12} className={classes.solutionCircles}>
				<Box display="flex" justifyContent="center" alignItems="center">
					<img src={Solution1} alt="scren1" height="100%" width="100%" />
				</Box>
			</Grid>
			<Grid item xs={12} sm={12} md={4} className={classes.powerCloseContainer}>
				<Grid container>
					<Typography className={classes.powerCloseText}>
						{" "}
						PowerClose
					</Typography>
				</Grid>
				<Grid className={classes.powerCloseGrid}>
					<Divider className={classes.powerCloseBorder} />
				</Grid>
				<Typography className={classes.solutionMetaText}>
					Powerclose helps automate, orchestrate, and manage start-to-end
					general ledger and sub-ledger close process across the enterprise.
					Offered in both cloud and on-premise models, Powerclose provides full
					integration to customer’s ERP and a workflow-based platform to execute
					and track global close activities.
				</Typography>
			</Grid>

			<Grid container className={classes.hubicContainer}>
				<Grid item md={5} sm={12} xs={12}>
					<Grid container>
						<Typography className={classes.hubicText}>Hubic</Typography>
					</Grid>
					<Grid className={classes.gridHubicGrid}>
						<Divider className={classes.gridHubicBorder} />
					</Grid>
					<Typography className={classes.hubicMetaText}>
						HUBIC is a solution to manage, track, and report on financial and
						tax impact of global intercompany transactions. Fully integrated
						with customer’s ERP, HUBIC provides a visual platform to initiate
						and track intercompany transactions and generate real-time global
						status and financial views. Built-in APIs provide seamless
						integration to source data from SCM systems as well as outbound
						integration to Snowflake. Built-in integration to Slack enables
						continuous collaboration on each individual transaction.
					</Typography>
				</Grid>
				<Grid item md={7} sm={12} xs={12} className={classes.solutionCircles2}>
					<Swiper
						slidesPerView={1}
						navigation={true}
						modules={[Navigation]}
						className={classes.swiper}
					>
						<SwiperSlide>
							<Box display="flex" justifyContent="center" alignItems="center">
								<img src={Solution3} alt="scren3" height="100%" width="100%" />
							</Box>
						</SwiperSlide>
						<SwiperSlide>
							<Box display="flex" justifyContent="center" alignItems="center">
								<img src={Solution4} alt="scren4" height="100%" width="100%" />
							</Box>
						</SwiperSlide>
					</Swiper>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Solution;
