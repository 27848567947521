import { makeStyles } from "@mui/styles";
import { Circles } from "../../images";

export const useStyles = makeStyles((theme) => ({
	heroGridContainer: {
		paddingTop: "9.375rem",
		paddingBottom: "10.625rem",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "0",
		},
	},
	heroText: {
		fontSize: "3rem !important",
		fontWeight: 500,
		lineHeight: "4rem !important",
		fontFamily: "OutfitRegular",
		color: "#fff",
		[theme.breakpoints.down("sm")]: {
			fontSize: "2.5rem !important",
			fontWeight: 500,
			lineHeight: "3rem !important",
		},
	},
	heroSmallerText: {
		fontSize: "2.5rem !important",
		fontWeight: 500,
		lineHeight: "2.5rem !important",
		fontFamily: "OutfitRegular",
		color: "#fff",
		[theme.breakpoints.down("sm")]: {
			fontSize: "2rem !important",
			fontWeight: 500,
			lineHeight: "2.3rem !important",
		},
	},
	gridGrid: {
		background: "#00CC8F",
		width: "11.813rem",
		marginTop: "2.125rem",
		marginBottom: "2.125rem",
	},
	gridBorder: {
		background: "#00CC8F",
		width: "11.813rem",
		height: "5px",
	},
	heroMetaText: {
		fontSize: "1.875rem !important",
		fontWeight: 400,
		lineHeight: "2.375rem !important",
		fontFamily: "OutfitRegular",
		color: "rgba(255, 255, 255, 0.8)",
		marginBottom: "3.125rem",
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.6rem !important",
			fontWeight: 400,
			lineHeight: "2rem !important",
		},
	},
	scheduleDemoBtn: {
		//marginTop: "3.125rem",
		// "& .MuiButton-root": {
		// 	padding: "20px 40px",
		// 	//gap: "10px",
		// 	width: "246px",
		// 	height: "70px",
		// 	backgroundColor: "#00CC8F",
		// 	borderRadius: "54px",
		// },
	},
	BtnText: {
		color: "#fff !important",
		fontWeight: 500,
		fontSize: "1.5rem",
		lineHeight: "1.875rem",
	},
	swiperGrid: {
		backgroundImage: `url(${Circles})`,
		backgroundRepeat: "no-repeat",
		objectFit: "fill",
		backgroundSize: "contain",
		backgroundPosition: "center",
		paddingTop: "7rem",
		paddingBottom: "7rem",
		//backgroundAttachment: "fixed",
		//backgroundPosition: '0 -8.438rem'
		//height: "635px",
	},

	fullNameText: {
		fontSize: "1.25rem !important",
		fontWeight: 500,
		lineHeight: "1.438 !important",
		fontFamily: "OutfitRegular",
		color: "#222222",
		marginBottom: "0.5rem",
	},
	mainText: {
		fontSize: "2rem !important",
		fontWeight: 600,
		lineHeight: "2.5rem !important",
		fontFamily: "OutfitRegular",
		color: "#0B3192",
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.5rem !important",
			fontWeight: 600,
			lineHeight: "2rem !important",
		},
	},
	mainMetaText: {
		fontSize: "1.5rem !important",
		fontWeight: 400,
		lineHeight: "40.8px !important",
		fontFamily: "OutfitRegular",
		color: "#222222",
		marginBottom: "0.5rem",
	},
}));
